// This script must require 'jquery'.
//
// param settings example
// elem: 'a[href^="#"]'
//
// in 'bundle.js', initialize example
// var scroll = new smtScr($('a[href^="#"]'));
// scroll.smtscr();
//

var $ = require('jquery');

class smtScr {
    constructor(elem) {
        this.elem = elem;
        this.smtscr(); // コンストラクタ関数実行時 = インスタンス生成時にsmtscr()を実行
    }
    scrlElm() {
        if ('scrollingElement' in document) {
            return document.scrollingElement;
        } if(navigator.userAgent.indexOf('WebKit') != -1) {
            return document.body;
        }
        return document.documentElement;
    }
    log() {
        return [this.elem, this.scrlElm(), this.smtscr()];
    }
    smtscr() {
        $(this.elem).on('click', ()=> {
            var speed = 1000,
                href= $(this.elem).attr('href'),
                target = $(href == '#' || href == '' ? 'html' : href),
                position = target.offset().top;
            $(this.scrlElm()).animate({scrollTop: position}, speed, 'swing');
            return false;
        });
        // return true;
    }
}

module.exports = smtScr;
