const $ = require('jquery');
const Swiper = require('swiper');
const smtScr = require('./smoothScroll');
const fa = require('@fortawesome/fontawesome');
const user = require('@fortawesome/fontawesome-free-solid/faUserCircle');
const money = require('@fortawesome/fontawesome-free-solid/faMoneyBillWave');
const exclam = require('@fortawesome/fontawesome-free-solid/faExclamationCircle');
const right = require('@fortawesome/fontawesome-free-solid/faAngleRight');
const male = require('@fortawesome/fontawesome-free-solid/faMale');
const female = require('@fortawesome/fontawesome-free-solid/faFemale');
const feat = require('@fortawesome/fontawesome-free-solid/faFlagCheckered');
const fist = require('@fortawesome/fontawesome-free-solid/faHandRock');
const leaf = require('@fortawesome/fontawesome-free-solid/faLeaf');
const tags = require('@fortawesome/fontawesome-free-solid/faTags');
const book = require('@fortawesome/fontawesome-free-solid/faBookOpen');
const menu = require('@fortawesome/fontawesome-free-solid/faBars');
const plus = require('@fortawesome/fontawesome-free-solid/faPlusCircle');
const question = require('@fortawesome/fontawesome-free-solid/faQuestionCircle');
const wallet = require('@fortawesome/fontawesome-free-solid/faWallet');
const plane = require('@fortawesome/fontawesome-free-solid/faPaperPlane');
const search = require('@fortawesome/fontawesome-free-solid/faSearch');
const crown = require('@fortawesome/fontawesome-free-solid/faCrown');
const home = require('@fortawesome/fontawesome-free-solid/faHome');
const TweenLite = require('gsap/TweenLite');
require('gsap/CSSPlugin');

(() => {
    return window.FontAwesomeConfig = { searchPseudoElements: true };
})();

fa.library.add(user, money, exclam, right, male, female, feat, fist, leaf, tags, book, menu, plus, question, wallet, plane, search, crown, home);

var ichi, ichi_h;
var res_flg = false;
var ichiCover, ichiThumb, ichiSlide;
var Prom;

document.addEventListener('DOMContentLoaded', function() {
    win_w = window.innerWidth;
    var slide, actSlide, $index, img, actImg, info, actInfo, transInfo, current, thumb, curThumb, actThumb, ichi_bg;
    ichi_bg = document.getElementById('ichi_bg');
    info = $('#i_info').find('.ichi_info_inner');
    thumb = $('#i_thumb').find('.ichi_thumb_outer');

    var ichiSwipe = document.getElementById('ichi_swipe');
    if (ichiSwipe) {
        ichiCover = new Swiper('#ichi_swipe', {
            speed: 600,
            autoHeight: false,
            centeredSlides: true,
            loop: true,
            slidesPerView: 'auto',
            spaceBetween: 25,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 45,
                stretch: 0,
                depth: 175,
            },
        });

        (function() {
            slide = $('.swiper-wrapper').find('.swiper-slide').not('.swiper-slide-duplicate');
            actSlide = $('.swiper-wrapper').find('.swiper-slide-active');
            $index = actSlide.attr('data-swiper-slide-index');
            info.each(function(i) {
                $(this).attr('data-info-index', i);
            });
            info[$index].classList.add('active');
            thumb.each(function(i) {
                $(this).attr('data-thumb-index', i);
            });
            thumb[$index].classList.add('active');
            img = slide.find('img');
            actImg = img[$index].src;
            current = info[$index];
            curThumb = thumb[$index];
            ichi_bg.style.backgroundImage = 'url(' + actImg + ')';
            ichi_bg.classList.add('active');
        })();

        ichiCover.on('transitionStart', function() {
            actSlide = $('.swiper-wrapper').find('.swiper-slide-active');
            $index = actSlide.attr('data-swiper-slide-index');
            actImg = img[$index].src;
            current.classList.add('trans');
            current.classList.remove('active');
            curThumb.classList.remove('active');
            ichi_bg.classList.remove('active');
        });

        ichiCover.on('transitionEnd', function() {
            ichi_bg.style.backgroundImage = 'url(' + actImg + ')';
            ichi_bg.classList.add('active');
            current.classList.remove('trans');
            current = info[$index];
            curThumb = thumb[$index];
            var arr;
            let prom = new Promise((res, rej) => {
                if (!current.classList.contains('active')) {
                    arr = [current, curThumb]
                    res(arr);
                } else {
                    rej(Error('undefined'));
                }
            });
            prom.then((arr) => {
                arr[0].classList.add('trans');
                arr[1].classList.add('active');
                return arr;
            }).then((arr) => {
                return new Promise((res, rej) => {
                    setTimeout(() => {
                        arr[0].classList.add('active');
                        res(arr);
                    });
                });
            }).then(function(arr) {
                arr[0].classList.remove('trans');
            }).then(function(){
                actInfo = $('.ichi_info_inner.active');
                actInfo.each(function(i) {
                    if ($(this).attr('data-info-index') != $index) {
                        $(this).removeClass('active');
                    }
                });
            }).catch((err) => {
                console.error(err);
            });
        });
    }
}, false);

$(".ichi_slide, .ichi_thumb_outer").on('click', function() {
    ichiCover.slideToLoop($(this).index(), 500);
});

// var pOuter = document.body.querySelector('.pickup');
// if (pOuter) {
//     $('.pickup_outer').each(function(i) {
//         $(this).on('click', function(i) {
//             console.log(this);
//         });
//     });
// }

var scroll = new smtScr($('a[href^="#"]'));